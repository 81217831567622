import { useEffect, useState } from "react"
import { captureMessage } from "@sentry/react"
import parse from "html-react-parser"

import PurchaseModal from "app/components/subscriptions/purchase_modal"
import Modal from "shared/components/modal"
import { CheckoutStep } from "./../modal_copy"
import closeX from "images/components/x.svg"
import { loadState, saveState } from "shared/localstorage_helper"
import moment from "moment"
import paidBenefits from "app/components/subscriptions/shared/paid_benefits"

import "./index.scss"
import classNames from "classnames"

interface URLs {
  modalSubscriptionUrl: string
  newProSubscriptionUrl: string
  newProPlusSubscriptionUrl: string
}

interface SubscriptionProps {
  assets: {
    iftttSvgUrl: string
    iftttWhieSvgUrl: string
    proBadgeSvgUrl: string
    proBadgeWhiteSvgUrl: string
    proPlusBadgeSvgUrl: string
    proPlusBadgeWhiteSvgUrl: string
  }
  browser_type: string
  create_stripe_customer_url: string
  eligible_for_trial: boolean
  email: string
  holiday_promo_code: string | null
  initial_promo_code: string | null
  intermediate_pro: { product_id: string; plan_id: string; tier: string; monthly_plan: object; yearly_plan: object }
  pro: { product_id: string; plan_id: string; tier: string; monthly_plan: object; yearly_plan: object }
  subscriptions_path: string
}

interface Props {
  holidayPromoCode: string | null
  initialPromoCode: string
  modalCopyObj: {
    checkoutModalTarget: "intermediate_pro" | "pro"
    eligibleForTrialCopy: string
    copy: string
    title: string
  }
  onClose: () => void
  onUpgrade: (a: string) => void
  step: CheckoutStep
  urls: URLs
  visible: boolean
}

const CheckoutModal = ({
  modalCopyObj,
  onClose,
  onUpgrade,
  step,
  urls,
  visible,
  initialPromoCode,
  holidayPromoCode,
}: Props) => {
  const [subscriptionProps, updateSubscriptionProps]: [SubscriptionProps, (a: SubscriptionProps) => void] =
    useState<SubscriptionProps>({} as SubscriptionProps)
  const [subscriptionPropsLoaded, setSubscriptionPropsLoaded]: [boolean, (a: boolean) => void] = useState(false)
  const { checkoutModalTarget, eligibleForTrialCopy, title } = modalCopyObj

  const checkoutStorageId = `checkoutModalSubscriptionProps-${window.App.user?.id}-v3`
  const loadCachedSubscriptionProps = () => JSON.parse(loadState(checkoutStorageId) || "null")
  const cacheSubscriptionProps = data => {
    saveState(
      JSON.stringify({
        ttl: moment().add(10, "minutes").unix(),
        data,
      }),
      checkoutStorageId
    )
  }

  const loadSubscriptionProps = () => {
    const cachedSubscriptionProps = loadCachedSubscriptionProps()

    if (cachedSubscriptionProps && moment().unix() < cachedSubscriptionProps.ttl) {
      if (window.App.env && window.App.allowDebugErrorMessages) {
        console.log("subscriptionProps loaded from cache", cachedSubscriptionProps)
      }
      updateSubscriptionProps(cachedSubscriptionProps.data)
      setSubscriptionPropsLoaded(true)
      return
    }

    fetch(urls.modalSubscriptionUrl, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      redirect: "error",
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("HTTP error " + response.status)
        }

        return response.json()
      })
      .then(result => {
        updateSubscriptionProps(result)
        setSubscriptionPropsLoaded(true)
        cacheSubscriptionProps(result)
      })
      .catch(() => {
        const modalTargetPlan = modalCopyObj.checkoutModalTarget
        // In case that we have an issue load data to be used in SignUpAndPurchase, not block user. Redirect them to checkout page.
        captureMessage("Checkout modal failed to load subscription data and redirect user to checkout page.", {
          tags: { user_id: window.App.user?.id },
          extra: { modalTargetPlan },
        })
        window.location.assign(
          modalCopyObj.checkoutModalTarget === "intermediate_pro"
            ? urls.newProSubscriptionUrl
            : urls.newProPlusSubscriptionUrl
        )
      })
  }

  useEffect(() => {
    loadSubscriptionProps()
  }, [])

  useEffect(() => {
    if (visible) {
      if (document.querySelectorAll("meta[name='turbolinks-cache-control']").length > 0) return

      const meta = document.createElement("meta")
      meta.name = "turbolinks-cache-control"
      meta.content = "no-cache"

      document.getElementsByTagName("head")[0].appendChild(meta)
    }
  }, [visible])

  const isHolidayPromo = step === "holiday"
  const headerStyle = classNames("onboarding-header", { "show": isHolidayPromo })

  return subscriptionPropsLoaded ? (
    <div styleName="modal-container checkout-on-modal" className="checkout-on-modal">
      <Modal show={visible} includeCloseX={false}>
        <section styleName="checkout-container">
          {isHolidayPromo ? (
            <section styleName="left" className="illustration-holiday">
              <div styleName="inner" />
            </section>
          ) : (
            <section styleName="left" className={`illustration-${modalCopyObj.checkoutModalTarget}`}>
              <div styleName="inner">
                <h1 styleName="ifttt">
                  <img src={subscriptionProps["assets"].iftttWhiteSvgUrl} alt="IFTTT logo" width="122" height="37" />
                  {checkoutModalTarget === "pro" ? (
                    <img src={subscriptionProps["assets"].proPlusBadgeWhiteSvgUrl} alt="Pro+ badge" width="91" height="53" />
                  ) : (
                    <img src={subscriptionProps["assets"].proBadgeWhiteSvgUrl} alt="Pro badge" width="91" height="53" />
                  )}
                </h1>
                <div styleName="cta-subheader">
                  {modalCopyObj.benefitsHeader}
                </div>
                <ul>
                  {paidBenefits[checkoutModalTarget].map((benefit, index) => (
                    <li key={index}>{benefit}</li>
                  ))}
                </ul>
              </div>
            </section>
          )}
          <section styleName="right">
            <div styleName="close" onClick={onClose}>
              <span className="close-x">{parse(closeX)}</span>
            </div>
            <header styleName={headerStyle} className="pb2 pb0--phone">
              <h1 styleName="ifttt">
                <img src={subscriptionProps["assets"].iftttSvgUrl} alt="IFTTT logo" width="122" height="37" />
                {checkoutModalTarget === "pro" ? (
                  <img src={subscriptionProps["assets"].proPlusBadgeSvgUrl} alt="Pro+ badge" width="91" height="53" />
                ) : (
                  <img src={subscriptionProps["assets"].proBadgeSvgUrl} alt="Pro badge" width="91" height="53" />
                )}
              </h1>
              <h2 className="alt-title" styleName="modal-title">
                {title}
              </h2>
              {isHolidayPromo && <p styleName="tech-the-halls">Tech the halls with our best prices of the year.</p>}
              {eligibleForTrialCopy ? (
                <p className="subheader txt-body-3">
                  {eligibleForTrialCopy}
                </p>
              ) : null}
            </header>
            <PurchaseModal
              onClose={onClose}
              onUpgrade={() => onUpgrade(checkoutModalTarget)}
              subscriptionProps={{
                ...subscriptionProps,
                initial_promo_code: initialPromoCode,
                holiday_promo_code: holidayPromoCode,
              }}
              target={checkoutModalTarget}
            />
          </section>
        </section>
      </Modal>
    </div>
  ) : null
}

export default CheckoutModal
