import StripeJsContainer from "./stripe_js_container"
import PaymentInfoForm from "./payment_info_form"

import { saveUserPermissions } from "shared/scripts/new_badge_button"

import "./sign_up_and_purchase.scss"
interface Plan {
  id: string
  amount: number
  interval: "month" | "year"
  interval_count: number
  name: string
  local_price: number
  local_price_formatted: string
  local_price_monthly: number
  local_price_formatted_monthly: string
  currency: string
}

interface SubscriptionProps {
  tier: string
  plan_id: string
  product_id: string
  monthly_plan: Plan
  yearly_plan: Plan
}

interface Props {
  onClose: () => void
  onUpgrade: () => void
  subscriptionProps: {
    product_id: string
    plan_id: string
    tier: string
    monthly_plan: object
    yearly_plan: object
    holiday_promo_code: string | null
    initial_promo_code: string | null
    subscriptions_path: string
    browser_type: string
    create_stripe_customer_url: string
    email: string
    eligible_for_trial: boolean
    pro: SubscriptionProps
    intermediate_pro: SubscriptionProps
  }
  target: "intermediate_pro" | "pro"
}

const PurchaseModal = ({ onClose, onUpgrade, subscriptionProps, target }: Props) => {
  const subscription = subscriptionProps[target]

  return (
    <StripeJsContainer>
      <div className="flexbox">
        <PaymentInfoForm
          productId={subscription["product_id"]}
          tier={subscription["tier"]}
          planId={subscription["plan_id"]}
          monthlyPlan={subscription["monthly_plan"]}
          yearlyPlan={subscription["yearly_plan"]}
          eligibleForTrial={subscriptionProps["eligible_for_trial"]}
          browserType={subscriptionProps["browser_type"]}
          email={subscriptionProps["email"]}
          referrerPath={window.location.href}
          urls={{
            createStripeCustomerUrl: subscriptionProps["create_stripe_customer_url"],
            subscriptionsPath: subscriptionProps["subscriptions_path"],
            subscriptionSuccessPath: "",
          }}
          initialPromoCode={subscriptionProps["initial_promo_code"]}
          holidayPromoCode={subscriptionProps["holiday_promo_code"]}
          onModalHandleSuccessfulSubscription={() => {
            const isProPlus = subscription["tier"] == "pro"
            const tierName = isProPlus ? "Pro+" : "Pro"
            window.appendFlash(`Upgrade complete. Welcome to IFTTT ${tierName}!`, "success")

            const multiActionPermission =
              subscription["tier"] == "intermediate_pro" ||
              (subscription["tier"] == "pro" && window.App.user.tier == "free")

            saveUserPermissions(subscription["tier"], {
              multi_action: { permitted: multiActionPermission },
              filter_code: { permitted: isProPlus },
              queries: { permitted: isProPlus },
              multi_service_account: { permitted: isProPlus },
            })
            onUpgrade()
            onClose()
          }}
          modalButtonType="new"
          renderingInsideModal={true}
          // props not used in modal mode
          redirectToMobileAppOnSuccess={false}
        />
      </div>
    </StripeJsContainer>
  )
}

export default PurchaseModal
